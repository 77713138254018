//Testing server connections

// export const base_url = "http://180.149.241.128:5011/";
// export const broker_url = "http://180.149.241.128:5011/";
// export const broker_redirect_url = "http://180.149.241.128:5011/";
// export const react_domain = "http://180.149.241.128:5010/";
// export const panel_name = "smartalgo";


//Live Connections Smart algo

export const base_url = "https://newapp.smartalgo.in:5011/";
export const broker_url = "https://newapp.smartalgo.in:5011/";
export const broker_redirect_url = "https://newapp.smartalgo.in:5011/";
export const react_domain = "https://newapp.smartalgo.in/";
export const panel_name = "newapp";
export const broker_signal_url = "https://api.smartalgo.in:3002/broker-signals";


// https://newapp.smartalgo.in:5011/